import * as React from 'react'
import { Link, browserHistory } from 'react-router'
import { inject, observer } from 'mobx-react'
import { CurrentCompanyStore } from '../../stores/CurrentCompanyStore'
import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink } from 'reactstrap'
import { EmployeeNavbar } from './EmployeeNavbar'
import { Routes } from '../../constants/Routes'
import { AntButtonWrapper } from '../../components/GrButton/AntButtonWrapper/AntButtonWrapper'
import { CashCounterModal } from './CashCount/CashCounterModal'

import { message } from 'antd-v4'
import { Alert } from 'antd'
import { parseErrorMsg } from '../../utilities/helpers'
import { CreateEndShift } from '@getgreenline/homi-shared'
import { POSStore } from '../../stores/POSStore'
interface Props {
  currentCompanyStore?: CurrentCompanyStore
  posStore?: POSStore
}

interface State {
  collapsed: boolean
  isCashCounterModalVisible: boolean
  menuExpanded: boolean
  createEndShift: CreateEndShift
}

function getSelectedStyleFromUrl(searchString: string): any {
  const url = window.location.pathname.toLowerCase()
  searchString = searchString.toLowerCase()
  return url.includes(searchString) ? { color: 'white' } : { color: 'gray' }
}

@inject('currentCompanyStore', 'posStore')
@observer
export class POSNavbar extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      collapsed: true,
      isCashCounterModalVisible: false,
      menuExpanded: false,
      createEndShift: new CreateEndShift(),
    }
  }

  UNSAFE_componentWillReceiveProps() {
    this.setState({ collapsed: true })
  }

  setIsCashCounterModalVisible = (isCashCounterModalVisible: boolean) =>
    this.setState({ isCashCounterModalVisible })

  setMenuExpanded = (menuExpanded: boolean) => this.setState({ menuExpanded })

  onEndShiftClick = () => {
    this.setIsCashCounterModalVisible(true)
    this.setMenuExpanded(false)
  }

  toggleNavbar() {
    this.setState({
      collapsed: !this.state.collapsed,
    })
  }

  endShift = async (passcodeValue?: string) => {
    try {
      this.state.createEndShift.setRegisterPasscode(passcodeValue || '')
      const endedShift = await this.props.posStore!.endShift(this.state.createEndShift)
      browserHistory.push(Routes.POS_SALE(`?lastShiftId=${endedShift.id}`))
      window.location.reload()
    } catch (error) {
      message.error(parseErrorMsg(error) || 'There was an error finishing this shift')
    } finally {
      this.setState({ isCashCounterModalVisible: false })
    }
  }

  onCancel = () => {
    this.state.createEndShift.setRegisterPasscode('')
    this.setState({ isCashCounterModalVisible: false })
  }

  render() {
    const company = this.props.currentCompanyStore!.company!
    const posStore = this.props.posStore
    const saleStyle = getSelectedStyleFromUrl('pos/sale')
    const paymentsStyle = getSelectedStyleFromUrl('pos/payments')
    const customersStyle = getSelectedStyleFromUrl('pos/customers')
    const settingsStyle = getSelectedStyleFromUrl('pos/settings')

    return (
      <Navbar
        className='text-white'
        fixed='top'
        expand={'xs'}
        style={{ backgroundColor: 'rgb(43, 51, 59)', zIndex: 1 }}
      >
        <NavbarBrand
          style={{ color: 'inherit' }}
          onClick={() => this.setMenuExpanded(!this.state.menuExpanded)}
        >
          <span className='fa fa-bars ml-2 text-white' />
        </NavbarBrand>
        <NavbarToggler onClick={() => this.toggleNavbar()} style={{ color: 'white' }}>
          <span className='fa fa-navicon mt-1' />
        </NavbarToggler>
        <li className='nav-item active' style={{ listStyle: 'none' }}>
          <EmployeeNavbar />
        </li>

        {posStore?.isCurrentShiftLong && (
          <div onClick={() => this.setIsCashCounterModalVisible(true)}>
            <Alert
              message='Your shift is running longer than 24 hours, please end this shift.'
              type='error'
              className='warning-msg'
              iconType='warning'
              showIcon
            />
          </div>
        )}

        <Collapse isOpen={!this.state.collapsed} navbar>
          <Nav className='ml-auto pos-navbar' navbar>
            <NavItem>
              <NavLink tag={Link} to={Routes.POS_SALE()} style={saleStyle}>
                Register
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink tag={Link} to={Routes.POS_PAYMENTS} style={paymentsStyle}>
                Sales
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink tag={Link} to={Routes.POS_VIEW_CUSTOMERS} style={customersStyle}>
                Customers
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink tag={Link} to={Routes.POS_SETTINGS} style={settingsStyle}>
                Settings
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink onClick={() => this.setState({ isCashCounterModalVisible: true })}>
                <AntButtonWrapper
                  buttonProps={{
                    type: 'danger',
                    // Button appears to be slightly higher than the rest. Adjust accordingly
                    style: { bottom: -2 },
                    onClick: this.onEndShiftClick,
                  }}
                  titleProps={{
                    title: 'End shift',
                  }}
                />
              </NavLink>
            </NavItem>
          </Nav>
        </Collapse>

        {this.state.menuExpanded && (
          <div
            id='pos-menu'
            className='background-shadow animated slideInLeft'
            style={
              {
                backgroundColor: 'rgb(43, 51, 59)',
                position: 'fixed',
                top: 0,
                left: 0,
                width: 300,
                height: '100vh',
                VendorAnimationDuration: '0.2s',
              } as any
            }
          >
            <div className='p-4'>
              <a className='pull-right' onClick={() => this.setMenuExpanded(false)}>
                <span className='fa fa-times text-white' />
              </a>
            </div>
            <Link
              to={Routes.POS_SALE()}
              className='text-white'
              onClick={() => this.setMenuExpanded(false)}
            >
              <div className='p-4'>
                <span className='fa fa-money mr-3' />
                POS register
              </div>
            </Link>
            <Link
              to={Routes.POS_PAYMENTS}
              className='text-white'
              onClick={() => this.setMenuExpanded(false)}
            >
              <div className='p-4'>
                <span className='fa fa-history mr-3' />
                Sales
              </div>
            </Link>
            <Link
              to={Routes.POS_VIEW_CUSTOMERS}
              className='text-white'
              onClick={() => this.setMenuExpanded(false)}
            >
              <div className='p-4'>
                <span className='fa fa-users mr-3' />
                Customers
              </div>
            </Link>
            <Link
              to={Routes.POS_SETTINGS}
              className='text-white'
              onClick={() => this.setMenuExpanded(false)}
            >
              <div className='p-4'>
                <span className='fa fa-cog mr-3' />
                Settings
              </div>
            </Link>

            <a className='text-white' onClick={this.onEndShiftClick}>
              <div className='p-4'>
                <span className='fa fa-power-off mr-3' />
                End shift
              </div>
            </a>
            <Link to='/' className='text-white' onClick={() => this.setMenuExpanded(false)}>
              <div className='p-4'>
                <span className='fa fa-sign-in mr-3' />
                Back to login page
              </div>
            </Link>
          </div>
        )}

        <CashCounterModal
          titlesFor={{
            modal: 'Float count',
            skipMessage: 'Count later',
            passwordMessage: 'End your shift',
            passwordButton: 'End shift',
          }}
          descriptionsFor={{
            skipMessage: 'Float counts can be added on the Dashboard',
            passwordMessage: 'You can print your end-of-shift receipt after ending',
          }}
          visible={this.state.isCashCounterModalVisible}
          onSuccess={this.endShift}
          cashCountObject={this.state.createEndShift}
          onCancel={this.onCancel}
          hasPassword
        />
      </Navbar>
    )
  }
}
