import React, { useState } from 'react'
import { Modal, Alert, Checkbox, Input, message } from 'antd'
import { CreateCashCount } from '@getgreenline/homi-shared'
import { GrPrice, parseErrorMsg } from '../../../utilities/helpers'
import { observer } from 'mobx-react-lite'
import { AntButtonWrapper } from '../../../components/GrButton/AntButtonWrapper/AntButtonWrapper'
import { CashCounterTable } from './CashCounterTable'

interface Props {
  titlesFor: {
    modal: string
    skipMessage?: string
    passwordMessage?: string
    passwordButton?: string
  }
  descriptionsFor?: {
    skipMessage?: string
    passwordMessage?: string
  }
  visible: boolean
  hasPassword?: boolean
  cashCountObject: CreateCashCount
  onSuccess: (passcode?: string) => Promise<void>
  onCancel?: () => void
  closable?: boolean
}

export const CashCounterModal = observer((props: Props) => {
  const { titlesFor, visible, cashCountObject, onSuccess, hasPassword, descriptionsFor, closable } =
    props
  const { cashCount, isCashCountFilled } = cashCountObject
  const {
    modal: modalTitle,
    skipMessage: skipMessageTitle,
    passwordMessage: passwordMessageTitle,
    passwordButton: passwordButtonTitle,
  } = titlesFor
  const { skipMessage: skipMessageDescription, passwordMessage: passwordMessageDescription } =
    descriptionsFor || {}

  const isNextButtonDisabled = !hasPassword
    ? false
    : cashCount === null
    ? false
    : !isCashCountFilled

  const nextButtonTitle = !hasPassword
    ? 'Continue'
    : isNextButtonDisabled
    ? 'Skip or add counts to proceed'
    : 'Next'

  const [page, setPage] = useState('count') // count or receipt
  const [isLoading, setIsLoading] = useState(false)
  const [passcode, setPasscode] = useState('')

  const onInputNumberChange = (coinDeno: number, value?: number | string | null) => {
    let count

    if (value === undefined || value === null || value === '') {
      count = null
    } else {
      const stringValue = value.toString().replace(/\D/g, '')
      count = parseInt(stringValue)
    }
    cashCountObject.setCashCountItem(coinDeno, count)
  }

  const submitFloat = async (passcode?: string) => {
    try {
      setIsLoading(true)
      await onSuccess(passcode)
    } catch (error) {
      message.error(parseErrorMsg(error) || 'There was an error processing this float amount')
    } finally {
      setIsLoading(false)
    }
  }

  const onCancel = () => {
    setPage('count')
    setPasscode('')
    props.onCancel && props.onCancel()
  }

  return (
    <Modal
      centered
      maskClosable={false}
      title={modalTitle}
      visible={visible}
      onCancel={onCancel}
      footer={null}
      closable={closable}
    >
      {page === 'count' && (
        <>
          {cashCount ? (
            <>
              <CashCounterTable cashCount={cashCount} onInputNumberChange={onInputNumberChange} />

              <h5 className='text-muted'>
                {`Total: ${GrPrice.convertCentToDollar(cashCountObject.totalCashCount)}`}
              </h5>
            </>
          ) : (
            <Alert
              type='warning'
              message='Skipping cash count'
              description={skipMessageDescription}
            />
          )}

          {hasPassword && (
            <Checkbox
              className='mt-4 mb-4'
              checked={cashCount === null}
              onChange={() => cashCountObject.setCashCount(cashCount === null ? 'default' : null)}
            >
              {skipMessageTitle}
            </Checkbox>
          )}

          <AntButtonWrapper
            buttonProps={{
              type: 'primary',
              size: 'large',
              className: 'w-100',
              disabled: isNextButtonDisabled,
              onClick: () => {
                hasPassword ? setPage('receipt') : submitFloat()
              },
            }}
            titleProps={{ title: nextButtonTitle }}
          />
        </>
      )}

      {page === 'receipt' && (
        <>
          <Alert
            showIcon
            className='mb-4'
            type='warning'
            message={passwordMessageTitle || 'Enter passcode to continue'}
            description={passwordMessageDescription}
          />

          <Input
            className='mb-4'
            placeholder='Passcode'
            type='password'
            size='large'
            value={passcode}
            onChange={(e) => setPasscode(e.target.value)}
          />

          {hasPassword && (
            <AntButtonWrapper
              buttonProps={{
                type: 'primary',
                size: 'large',
                className: 'w-100',
                loading: isLoading,
                disabled: !passcode,
                onClick: () => submitFloat(passcode),
              }}
              titleProps={{ title: passwordButtonTitle || 'Continue' }}
            />
          )}
        </>
      )}
    </Modal>
  )
})
