import React from 'react'
import { Card, Col } from 'antd-v4'
import { AntButtonWrapper } from '../../../components/GrButton/AntButtonWrapper/AntButtonWrapper'
import { ILocation, IAdminExternalSource } from '@getgreenline/homi-shared'
import { ISubscriptionContract } from '@getgreenline/webhooks'

interface Props {
  subscription: ISubscriptionContract
  locations: ILocation[]
  externalSources: IAdminExternalSource[]
  onDelete: (subscription: ISubscriptionContract) => Promise<void>
  setIsEditing: (isEditing: boolean) => void
  setSubscriptionToEdit: (subscription: ISubscriptionContract) => void
}

export const SubscriptionCard = (props: Props) => {
  const { companyData } = props.subscription
  const { eventType, locationId, integratorId, url } = companyData

  const getExternalSourceName = (integratorId: string) => {
    const parsedIntegratorId = parseInt(integratorId)
    const source = props.externalSources.find((source) => source.id === parsedIntegratorId)
    return source?.name
  }

  const getLocationName = (locationId: number | undefined) => {
    const location = props.locations.find((location) => location.id === locationId)
    return location?.name
  }

  return (
    <Col xxl={6} xl={6} lg={8} md={12} sm={24} xs={24}>
      <Card
        title={
          <div>
            <div>{eventType}</div>
          </div>
        }
        className='mb-2'
      >
        <pre>
          Location: {getLocationName(locationId) || ''}
          <br />
          Url: {url}
          <br />
          Integrator: {getExternalSourceName(integratorId) || ''}
        </pre>
        <>
          <AntButtonWrapper
            buttonProps={{
              onClick: () => {
                props.setIsEditing(true)
                props.setSubscriptionToEdit(props.subscription)
              },
            }}
            titleProps={{
              title: 'Edit',
            }}
          />
          <AntButtonWrapper
            buttonProps={{
              onClick: () => {
                props.onDelete(props.subscription)
              },
            }}
            titleProps={{
              title: 'Delete',
            }}
          />
        </>
      </Card>
    </Col>
  )
}
