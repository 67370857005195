import * as React from 'react'
import { Drawer, List, Checkbox, message, Row, Col } from 'antd'
import { observer, Observer } from 'mobx-react-lite'
import { LoadingAnimation } from '../../../components/LoadingAnimation'
import { AntButtonWrapper } from '../../../components/GrButton/AntButtonWrapper/AntButtonWrapper'
import { API } from '@getgreenline/homi-shared'
import { parseErrorMsg } from '../../../utilities/helpers'
import { AdminExternalSourceStore } from './AdminExternalSourceStore'

interface Props {
  title?: string
  isDrawerOpen: boolean
  adminExternalSourceStore: AdminExternalSourceStore
  setIsDrawerOpen: (isDrawerOpen: boolean) => void
}

export const PermissionDrawer = observer((props: Props) => {
  const { title, isDrawerOpen, adminExternalSourceStore, setIsDrawerOpen } = props
  const {
    availablePermissions,
    selectedExternalSource,
    updatedExternalSourceObject: updateExternalSourceObject,
  } = adminExternalSourceStore

  const [isLoading, setIsLoading] = React.useState(false)

  const submitPermissions = async () => {
    if (!selectedExternalSource || !updateExternalSourceObject) {
      return
    }

    try {
      setIsLoading(true)

      const updatedExternalSource = await API.updateExternalSourceById(
        selectedExternalSource.id,
        updateExternalSourceObject,
      )

      adminExternalSourceStore.setSelectedExternalSource(updatedExternalSource)
      adminExternalSourceStore.mappedExternalSources.set(
        updatedExternalSource.id,
        updatedExternalSource,
      )
      message.success('Permissions successfully updated')
    } catch (error) {
      message.error(parseErrorMsg(error) || 'An error occurred while updating permissions')
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Drawer
      title={
        <Row className='d-flex justify-content-between align-items-center'>
          <Col>{title}</Col>

          <AntButtonWrapper
            buttonProps={{
              type: 'primary',
              loading: isLoading,
              disabled: isLoading,
              onClick: submitPermissions,
            }}
            titleProps={{
              title: 'Update',
            }}
          />
        </Row>
      }
      maskClosable
      placement='right'
      closable={false}
      width={350}
      onClose={() => {
        setIsDrawerOpen(false)
        adminExternalSourceStore.setSelectedExternalSource()
      }}
      visible={isDrawerOpen}
    >
      {availablePermissions === undefined ? (
        <LoadingAnimation green />
      ) : (
        <>
          <Checkbox
            className='ml-1 mb-3'
            checked={adminExternalSourceStore.areAllItemsChecked}
            onClick={adminExternalSourceStore.toggleAllPermissionChecks}
          >
            {`${adminExternalSourceStore.areAllItemsChecked ? 'Uncheck' : 'Check'} all`}
          </Checkbox>

          <List
            bordered
            dataSource={adminExternalSourceStore.availablePermissions}
            renderItem={(permissionItem) => {
              return (
                <Observer>
                  {() => (
                    <List.Item>
                      <Checkbox
                        onChange={() => permissionItem.setChecked(!permissionItem.checked)}
                        checked={permissionItem.checked}
                      >
                        {permissionItem.permission.name}
                      </Checkbox>
                    </List.Item>
                  )}
                </Observer>
              )
            }}
          />
        </>
      )}
    </Drawer>
  )
})
