import * as React from 'react'
import { AntAuditInputNumber } from '../../../DashboardInventory/AuditV2/EditAudit/components/AntAuditInputNumber'
import { CreateChildProduct, CreateProduct } from '../ProductStore'
import { isNil } from 'lodash'

interface Props {
  showMaxMin: boolean
  disabled: boolean
  className?: string
  onChange: (key: string, value: number | null, cannabinoidName: string) => void
  max: string | null | undefined
  min: string | null | undefined
  cannabinoid: string | null | undefined
  product: CreateProduct | CreateChildProduct
  cannabinoidName: string
}

const convertToString = (value: string | number | undefined) => {
  if (value === '' || isNil(value)) {
    return ''
  }
  return `${isNaN(Number(value)) ? '' : value}`
}

export const CannabinoidInputFields = (props: Props) => {
  const handleChange = (key: string, value: number | undefined, cannabinoidName: string) => {
    props.onChange(key, value === undefined ? null : value, cannabinoidName)
    props.product.changed = true
  }

  return (
    <div className='d-flex flex-row flex-wrap'>
      {props.showMaxMin ? (
        <div>
          <div className={'d-flex flex-row flex-wrap'}>
            <div className={props.className}>
              <label>Min</label>
              <AntAuditInputNumber
                className='d-flex justify-content-center'
                isEditable={!props.disabled}
                value={props.min ? +props.min : null}
                min={0}
                onChange={(value) => {
                  handleChange('min', value, props.cannabinoidName)
                }}
                formatter={convertToString}
              />
            </div>

            <div className={props.className}>
              <label>Max</label>
              <AntAuditInputNumber
                className='d-flex justify-content-center'
                isEditable={!props.disabled}
                value={props.max ? +props.max : null}
                min={0}
                onChange={(value) => {
                  handleChange('max', value, props.cannabinoidName)
                }}
                formatter={convertToString}
              />
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className={props.className}>
            <AntAuditInputNumber
              className='d-flex justify-content-center'
              isEditable={!props.disabled}
              value={props.cannabinoid ? +props.cannabinoid : null}
              min={0}
              onChange={(value) => {
                handleChange('', value, props.cannabinoidName)
              }}
              formatter={convertToString}
            />
          </div>
        </div>
      )}
    </div>
  )
}
