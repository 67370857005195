import * as React from 'react'
import { inject, observer } from 'mobx-react'
import { CurrentCompanyStore } from '../../stores/CurrentCompanyStore'
import { POSStore } from '../../stores/POSStore'
import { CurrencyInput } from '../../components/CurrencyInput'
import { Popover, PopoverHeader, PopoverBody } from 'reactstrap'
import { ReceiptModal } from './Receipt/ReceiptModal'
import { notification } from 'antd'
import { browserHistory } from 'react-router'
import { Routes } from '../../constants/Routes'
import { AntButtonWrapper } from '../../components/GrButton/AntButtonWrapper/AntButtonWrapper'
import ButtonGroup from 'antd/lib/button/button-group'
import { CashCounterModal } from './CashCount/CashCounterModal'
import { CreateCashCount } from '@getgreenline/homi-shared'
import { InfoCircleFilled } from '@ant-design/icons'

interface Props {
  posStore?: POSStore
  currentCompanyStore?: CurrentCompanyStore
  location: any
}

interface State {
  startCash: number
  notes: string
  passcode: string
  isSaving?: boolean
  showNotes?: boolean
  isReceiptModalVisible: boolean
  lastShiftId?: number
  cashCounterObject: CreateCashCount
  isCashCounterModalVisible: boolean
}

@inject('posStore', 'currentCompanyStore')
@observer
export class ShiftLogin extends React.Component<Props, State> {
  company = this.props.currentCompanyStore!.company!

  device = this.props.posStore!.device!

  onboarding: boolean

  constructor(props: Props) {
    super(props)
    this.onboarding = false
    this.state = {
      startCash: 0,
      isSaving: false,
      notes: '',
      passcode: '',
      showNotes: false,
      isReceiptModalVisible: false,
      lastShiftId: undefined,
      cashCounterObject: new CreateCashCount(),
      isCashCounterModalVisible: false,
    }
  }

  componentDidMount() {
    const queryObject = this.props.location.query
    const lastShiftId = queryObject.lastShiftId

    if (queryObject.onboarding) {
      this.onboarding = true

      notification.info({
        message: 'Welcome onboard!',
        description:
          'By default your passcode is 1234. If you have changed it in the dashboard, feel free to change it.',
        duration: 0,
      })

      this.setState({ passcode: '1234', startCash: 30000 })
    }

    if (lastShiftId !== undefined) {
      this.setIsReceiptModalVisible(true)
      this.setLastShiftId(lastShiftId)
    }

    browserHistory.push(Routes.POS_SALE())
  }

  setIsReceiptModalVisible = (isReceiptModalVisible: boolean) =>
    this.setState({ isReceiptModalVisible })

  setLastShiftId = (lastShiftId: number) => this.setState({ lastShiftId })

  save() {
    this.setState({ isSaving: true })
    this.props
      .posStore!.createShift({
        registerPasscode: this.state.passcode,
        startCash: Math.abs(this.state.startCash),
        notes: this.state.notes,
      })
      .then(() => {
        this.props.posStore!.trackHoursWithPasscode(this.state.passcode!)
      })
      .catch((error) => {
        notification.error({
          message: 'Error',
          description: error.response.data.message || 'There was an error starting your shift',
          duration: 0,
        })

        this.setState({ isSaving: false })
      })
  }

  onSuccess = async () => {
    this.setState({ startCash: this.state.cashCounterObject.totalCashCount })
    this.setState({ isCashCounterModalVisible: false })
  }

  get isSignInButtonDisabled(): boolean {
    return !this.state.passcode
  }

  get signInButtonTitle(): string {
    if (!this.state.passcode) {
      return 'Enter passcode'
    }

    return 'Start shift'
  }

  render() {
    const { isSaving, startCash, passcode, showNotes, notes, isReceiptModalVisible, lastShiftId } =
      this.state

    return (
      <div id='pos-login'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-6'>
              <div className='container'>
                {this.company.logoImageUrl ? (
                  <div>
                    <img
                      className='mb-2'
                      src={this.company.logoImageUrl}
                      style={{ height: '10em' }}
                    />
                  </div>
                ) : undefined}
                <h1 className='display-4'>
                  <span>{this.company.name}</span>{' '}
                  <span className='text-muted'>{this.device.deviceId}</span>
                </h1>
                <br />
                <h3>Start your shift</h3>
              </div>
            </div>

            <div className='col-md-6'>
              <form className='form'>
                <div className='form-group'>
                  <label>Starting float</label>
                  <div className='d-flex'>
                    <CurrencyInput
                      id='start-float-input'
                      className='form-control form-control-lg w-75'
                      centValue={startCash}
                      onlyPositiveNumber
                      onChange={(value) => {
                        this.setState({ startCash: value })
                        this.state.cashCounterObject.setCashCount('default')
                      }}
                    />
                    <button
                      className='btn btn-light w-50'
                      onClick={(e) => {
                        e.preventDefault()
                        this.setState({ isCashCounterModalVisible: true })
                      }}
                      style={{ border: '1px solid #C4C4C4' }}
                    >
                      Calculator
                    </button>
                  </div>
                  {this.state.startCash ? (
                    <div className='text-success mt-1 mb-4'>
                      <InfoCircleFilled translate className='mr-2 mb-2' />
                      <small>Calculator erases if Starting Float is manually updated</small>
                    </div>
                  ) : null}

                  {this.onboarding && (
                    <Popover
                      className='background-shadow'
                      placement='left'
                      isOpen={true}
                      target='start-float-input'
                    >
                      <PopoverHeader>Start your shift</PopoverHeader>
                      <PopoverBody>
                        A starting float is required. For instance, type: $300.00
                      </PopoverBody>
                    </Popover>
                  )}
                </div>
                {showNotes ? (
                  <div className='form-group'>
                    <label>
                      Notes{' '}
                      <a onClick={() => this.setState({ notes: '', showNotes: false })}>Hide</a>
                    </label>
                    <input
                      className='form-control form-control-lg'
                      value={notes}
                      onChange={(e) => this.setState({ notes: e.target.value })}
                    />
                  </div>
                ) : (
                  <div className='mt-2 mb-3'>
                    <a onClick={() => this.setState({ showNotes: true })}>Add notes (optional)</a>
                  </div>
                )}

                <div className='form-group'>
                  <label>Your passcode</label>
                  <input
                    className='form-control form-control-lg'
                    type='password'
                    value={passcode}
                    onChange={(e) => this.setState({ passcode: e.target.value })}
                  />
                </div>

                <ButtonGroup className='w-100 mt-4 mb-4'>
                  <AntButtonWrapper
                    buttonProps={{
                      className: this.state.lastShiftId === undefined ? 'w-100' : 'w-50',
                      size: 'large',
                      type: 'primary',
                      loading: isSaving,
                      disabled: this.isSignInButtonDisabled,
                      onClick: () => this.save(),
                    }}
                    titleProps={{ title: this.signInButtonTitle }}
                  />

                  {this.state.lastShiftId !== undefined && (
                    <AntButtonWrapper
                      buttonProps={{
                        className: 'w-50',
                        size: 'large',
                        type: 'link',
                        disabled: this.state.lastShiftId === undefined,
                        onClick: () => this.setIsReceiptModalVisible(true),
                      }}
                      titleProps={{ title: 'View last shift' }}
                    />
                  )}
                </ButtonGroup>

                <AntButtonWrapper
                  buttonProps={{
                    className: 'w-100 mt-4',
                    type: 'danger',
                    onClick: this.props.posStore!.logout,
                  }}
                  titleProps={{ title: 'Log out of device' }}
                />
              </form>
            </div>
          </div>
        </div>

        {isReceiptModalVisible && lastShiftId !== undefined && (
          <ReceiptModal
            lastShiftId={lastShiftId}
            visible={isReceiptModalVisible}
            setIsReceiptModalVisible={this.setIsReceiptModalVisible}
          />
        )}

        <CashCounterModal
          titlesFor={{
            modal: 'Start Float Count',
          }}
          visible={this.state.isCashCounterModalVisible}
          onSuccess={this.onSuccess}
          cashCountObject={this.state.cashCounterObject}
          closable={false}
        />
      </div>
    )
  }
}
