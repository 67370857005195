import * as React from 'react'
import { LocationModels } from '@getgreenline/locations'
import { AntButtonWrapper } from '../../../../../components/GrButton/AntButtonWrapper/AntButtonWrapper'
import { observer, inject } from 'mobx-react'
import { CreateProduct, CreateChildProduct } from '../ProductStore'
import { CurrentCompanyStore } from '../../../../../stores/CurrentCompanyStore'
import { MultiLocationCannabinoid } from './MultiLocationCannabinoid'
import { observable } from 'mobx'
import { CustomDivider } from '../../../../../components/CustomDivider'
import { CurrentUserStore } from '../../../../../stores/CurrentUserStore'
import { PermissionNames } from '@getgreenline/homi-shared'

interface InjectedProps {
  currentCompanyStore?: CurrentCompanyStore
  currentUserStore?: CurrentUserStore
}

interface Props extends InjectedProps {
  product: CreateProduct | CreateChildProduct
  disabled: boolean
}

@inject('currentCompanyStore', 'currentUserStore')
@observer
export class MultiLocationCannabinoids extends React.Component<Props> {
  LIMIT = 250

  @observable
  mappedGlobalLocations = new Map<number, LocationModels.IGlobalLocationContract>()

  componentDidMount = async () => {
    if (!this.props.currentCompanyStore!.locations) {
      this.props.currentCompanyStore!.getLocations()
    }

    ;(this.props.currentCompanyStore!.globalLocations || []).forEach((location) => {
      this.mappedGlobalLocations.set(location.id, location)
    })
  }

  get orderedPermittedLocations() {
    return this.userPermittedLocations.sort((a, b) => a.name.localeCompare(b.name))
  }

  get allLocationsUsed() {
    const locationCnbs = this.props.product.cannabinoidMetaData.cannabinoids.filter(
      (cnb) => cnb.isLocationCannabinoid,
    )
    const allSelectedLocationIds = locationCnbs.reduce((prev: number[], cnb) => {
      return [...prev, ...cnb.locationIds]
    }, [])

    const permittedLocationSet = new Set(this.userPermittedLocations.map((loc) => loc.id))

    for (const selectedLoc of allSelectedLocationIds) {
      permittedLocationSet.delete(selectedLoc)
    }

    return permittedLocationSet.size === 0
  }

  get userPermittedLocations() {
    const { currentCompanyStore, currentUserStore } = this.props

    if (!currentCompanyStore?.locations || !currentUserStore) {
      return []
    }

    const locations = currentCompanyStore.locations
    return locations.filter((location) =>
      currentUserStore.hasPermissionAtEntity(PermissionNames.CAN_EDIT_PRODUCTS, location.id),
    )
  }

  get locationCannabinoids() {
    const { cannabinoids } = this.props.product.cannabinoidMetaData
    return cannabinoids.filter((cbn) => cbn.isLocationCannabinoid)
  }

  render() {
    const { product } = this.props
    const { cannabinoidMetaData } = product

    return (
      <div>
        <CustomDivider title='Multi-location THC/CBD' className='mt-0' />

        <AntButtonWrapper
          titleProps={{
            title: this.allLocationsUsed ? 'All locations selected' : 'Add cannabinoid profile',
          }}
          buttonProps={{
            icon: 'plus',
            className: 'mb-3',
            disabled: this.props.disabled || this.allLocationsUsed,
            onClick: () => {
              cannabinoidMetaData.addCannabinoid(product.metaData.unit)
            },
          }}
        />

        {cannabinoidMetaData.cannabinoids.map((cnb, index) =>
          cnb.isLocationCannabinoid !== false ? (
            <MultiLocationCannabinoid
              key={index}
              index={index}
              disabled={this.props.disabled}
              product={this.props.product}
              cannabinoid={cnb}
              orderedPermittedLocations={this.orderedPermittedLocations}
              mappedGlobalLocations={this.mappedGlobalLocations}
            />
          ) : null,
        )}
      </div>
    )
  }
}
