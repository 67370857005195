import * as React from 'react'
import { API, ProvinceEnum } from '@getgreenline/homi-shared'
import { capitalizeFirstLetter, parseErrorMsg } from '../../utilities/helpers'
import { Button, Layout, Empty, Icon, Tag, message, Input, Row, Select } from 'antd'
import { inject } from 'mobx-react'
import { CurrentCompanyStore } from '../../stores/CurrentCompanyStore'
import '../Dashboard/index.scss'
import { browserHistory } from 'react-router'
import { IBaseCursorPaginationFilter, ICursorPagination } from '@getgreenline/shared'
import { CompaniesApi } from '@getgreenline/companies'
import {
  CustomTablePaginationV2,
  TPaginationPosition,
} from '../../components/CustomTablePaginationV2'
import { AntButtonWrapper } from '../../components/GrButton/AntButtonWrapper/AntButtonWrapper'
import { ICompany, ICompanyFilter } from '@getgreenline/companies/build/models'

interface State {
  companies?: ICompany[]
  mode: 'paid' | 'free'
  selectedCompanyId?: number
  showProductsPage: boolean
  pagination: ICursorPagination
  currentCursor: string | null
  isLoading: boolean
  query: string
  appliedQuery: string
  showSearched: boolean
  selectedProvince: ProvinceEnum | undefined
  appliedSelectedProvince: ProvinceEnum | undefined
  paginationReset: boolean
}

interface Props {
  currentCompanyStore?: CurrentCompanyStore
}

@inject('currentCompanyStore')
export class AdminCompaniesV2 extends React.Component<Props, State> {
  LIMIT = 8

  constructor(props: {}) {
    super(props)
    this.state = {
      companies: [],
      mode: 'paid',
      showProductsPage: false,
      pagination: {
        nextCursor: null,
        prevCursor: null,
      },
      currentCursor: null,
      isLoading: false,
      query: '',
      appliedQuery: '',
      showSearched: false,
      selectedProvince: undefined,
      appliedSelectedProvince: undefined,
      paginationReset: false,
    }
  }

  componentDidMount() {
    this.refreshData()
  }

  refreshData = async (
    cursor?: string | null,
    filter?: string,
    province?: ProvinceEnum | undefined,
  ) => {
    this.setState({ currentCursor: cursor || null })
    try {
      this.setState({ isLoading: true })
      const payload: ICompanyFilter & IBaseCursorPaginationFilter = {
        limit: this.LIMIT,
        id: filter,
        name: filter,
        province,
      }
      if (cursor) {
        payload.cursor = cursor
      }
      const { data, pagination } = await CompaniesApi.getCursorPaginatedCompanies(payload)
      this.setState({ pagination, companies: data })
      return data
    } catch (error) {
      message.error(parseErrorMsg(error) || 'An error occurred while retrieving the customer list')
    } finally {
      this.setState({ isLoading: false })
    }
  }

  applyFiltersOnPageChange = async (cursor?: string | null) => {
    await this.refreshData(cursor, this.state.appliedQuery, this.state.appliedSelectedProvince)
  }

  onSearchByEnterKey = () => {
    const isQueryExist = this.state.query.trim().length > 0

    this.setState({
      appliedQuery: this.state.query,
      appliedSelectedProvince: this.state.selectedProvince,
      showSearched: isQueryExist,
      paginationReset: true,
    })

    isQueryExist
      ? this.refreshData('', this.state.query, this.state.selectedProvince)
      : message.warn(`Please type company's ID or name`)
  }

  get provinces(): Array<{ text: string; value: ProvinceEnum }> {
    return [
      {
        text: ProvinceEnum.AB,
        value: ProvinceEnum.AB,
      },
      {
        text: ProvinceEnum.BC,
        value: ProvinceEnum.BC,
      },
      {
        text: ProvinceEnum.MB,
        value: ProvinceEnum.MB,
      },
      {
        text: ProvinceEnum.ON,
        value: ProvinceEnum.ON,
      },
      {
        text: ProvinceEnum.SK,
        value: ProvinceEnum.SK,
      },
      {
        text: ProvinceEnum.NU,
        value: ProvinceEnum.NU,
      },
      {
        text: ProvinceEnum.NT,
        value: ProvinceEnum.NT,
      },
    ]
  }

  setPaginationFirstPage = (paginationReset: boolean) => {
    this.setState({ paginationReset })
  }

  render() {
    const {
      query,
      appliedQuery,
      isLoading,
      paginationReset,
      showSearched,
      selectedProvince,
      appliedSelectedProvince,
    } = this.state

    let companiesToDisplay: ICompany[] = this.state.companies || []
    if (this.state.mode === 'paid') {
      companiesToDisplay = companiesToDisplay.filter((company) => company.isPaid)
    } else if (this.state.mode === 'free') {
      companiesToDisplay = companiesToDisplay.filter((company) => !company.isPaid)
    }

    return (
      <>
        <Layout>
          <Layout.Sider
            className='background-shadow'
            width={300}
            style={{
              overflow: 'auto',
              height: '100vh',
              position: 'fixed',
              background: '#fff',
              left: 0,
            }}
          >
            <div style={{ padding: 10, height: '90%', overflowY: 'scroll' }}>
              <div className='d-flex row ml-1'>
                <Button
                  key='paid'
                  type={this.state.mode === 'paid' ? 'primary' : 'default'}
                  onClick={() => this.setState({ mode: 'paid' })}
                  style={{ marginRight: 5 }}
                >
                  Paid
                </Button>
                <Button
                  key='free'
                  type={this.state.mode === 'free' ? 'primary' : 'default'}
                  onClick={() => this.setState({ mode: 'free' })}
                  style={{ marginRight: 5 }}
                >
                  Free
                </Button>
              </div>
              <div className='d-flex row ml-1 mt-2'>
                <Input
                  className='gr-filter-item gr-filter-gutter'
                  style={{ width: '90%' }}
                  placeholder='ID or name'
                  value={query}
                  onChange={(e) => this.setState({ query: e.target.value })}
                  onPressEnter={this.onSearchByEnterKey}
                />

                <Select<ProvinceEnum | undefined>
                  className='gr-filter-item gr-filter-gutter'
                  style={{ width: '40%' }}
                  placeholder='Province'
                  allowClear
                  dropdownMatchSelectWidth={false}
                  value={selectedProvince}
                  onChange={(value) => this.setState({ selectedProvince: value })}
                >
                  {this.provinces.map((statusOption, index) => (
                    <Select.Option key={index} value={statusOption.value}>
                      {statusOption.text}
                    </Select.Option>
                  ))}
                </Select>

                <Row className='gr-filter-gutter'>
                  <AntButtonWrapper
                    buttonProps={{
                      type: 'primary',
                      loading: isLoading,
                      onClick: () => {
                        this.refreshData('', query, selectedProvince)
                        this.setState({
                          appliedQuery: query,
                          appliedSelectedProvince: selectedProvince,
                          showSearched: true,
                          paginationReset: true,
                        })
                      },
                    }}
                    titleProps={{ title: 'Apply' }}
                  />

                  <AntButtonWrapper
                    buttonProps={{
                      className: 'text-secondary',
                      type: 'link',
                      onClick: async () => {
                        this.setState({
                          query: '',
                          appliedQuery: '',
                          selectedProvince: undefined,
                          appliedSelectedProvince: undefined,
                          showSearched: false,
                          paginationReset: true,
                        })
                        await this.refreshData(null, '', undefined)
                      },
                    }}
                    titleProps={{ title: 'Reset' }}
                  />
                </Row>
              </div>
              <CustomTablePaginationV2
                hideRowOptions={true}
                changePage={(cursor) => this.applyFiltersOnPageChange(cursor)}
                data={this.state.companies || []}
                paginationPosition={TPaginationPosition.BOTTOM}
                pagination={this.state.pagination}
                paginationReset={paginationReset}
                setPaginationFirstPage={this.setPaginationFirstPage}
                limit={this.LIMIT}
              >
                <div>
                  {companiesToDisplay.map((company) => {
                    return (
                      <div
                        key={company.id}
                        className={`${!!company.bannerMessage && 'text-danger'}`}
                        style={{ paddingTop: 10, paddingBottom: 10, cursor: 'pointer' }}
                        onClick={() => {
                          browserHistory.push(`/admin/v2/companies/${company.id}`)
                        }}
                      >
                        <>
                          <div>
                            {!!company.bannerMessage && <Icon type='warning' />} {company.id}:{' '}
                            {company.name}
                          </div>
                          <div>
                            <Tag>{company.province || '-'}</Tag>
                            {company.auditCompleteMode === 'replace' ? (
                              <Tag color='orange'>Audit - replace</Tag>
                            ) : (
                              <Tag color='green'>Audit - relative</Tag>
                            )}
                            {company.inventoryCostOption === 'lastCost' ? (
                              <Tag color='red'>Last cost</Tag>
                            ) : (
                              <Tag color='green'>Weighted average</Tag>
                            )}
                          </div>
                        </>
                      </div>
                    )
                  })}
                </div>
              </CustomTablePaginationV2>
            </div>
          </Layout.Sider>
          <Layout style={{ marginLeft: 300, borderLeft: '1px solid', backgroundColor: 'white' }}>
            {this.props.children}
          </Layout>
        </Layout>
      </>
    )
  }
}
