import { MetaDataModels } from '@getgreenline/products'
import React from 'react'
import { AntButtonWrapper } from '../../../../../components/GrButton/AntButtonWrapper/AntButtonWrapper'
import { CannabinoidMetaDataItem } from '../ProductStore'

interface Props {
  cannabinoid: CannabinoidMetaDataItem
  disabled: boolean
  onClick: () => void
}

export class TerpeneButton extends React.Component<Props> {
  makeTerpeneName = () => {
    let terpenes = Object.keys(MetaDataModels.TerpeneReservedKeys)
      .map((key) => {
        return MetaDataModels.TerpeneReservedKeys[key]
      })
      .filter((value) => {
        return this.props.cannabinoid.findMetaData(value)
      })
      .join(', ')

    terpenes = 'Terpenes: ' + (terpenes ? terpenes : 'N/A')

    if (terpenes.length > 30) {
      terpenes = terpenes.substring(0, 30) + '...'
    }

    return terpenes
  }

  render() {
    return (
      <div className='d-block mr-2 mb-2'>
        <AntButtonWrapper
          titleProps={{ title: this.makeTerpeneName() }}
          buttonProps={{
            onClick: this.props.onClick,
            disabled: this.props.disabled,
          }}
        ></AntButtonWrapper>
      </div>
    )
  }
}
