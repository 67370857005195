import './colors.scss'
import './styles/font-awesome/scss/font-awesome.scss'
import 'react-toggle/style.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'react-select/dist/react-select.css'
import './styles/global.scss'
import './styles/svg-loaders/tail-spin.svg'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import 'react-vis/dist/style.css'
import 'react-datepicker/dist/react-datepicker.css'
import 'antd/dist/antd.css'

import * as React from 'react'
import * as ReactDOM from 'react-dom'
import ReactGA from 'react-ga'
import { Router, IndexRedirect, Route, Redirect, browserHistory } from 'react-router'
import { configure } from 'mobx'
import { Provider } from 'mobx-react'
import { overwriteBirchmountAPIUrl } from '@getgreenline/homi-shared'
import * as Sentry from '@sentry/react'
import { CaptureConsole } from '@sentry/integrations'
import { ServerStore } from './stores/ServerStore'
import { CurrentCompanyStore } from './stores/CurrentCompanyStore'

import { App } from './App'
import { Deck } from './containers/Deck'
import { Login as LoginV2 } from './containers/Dashboard/LoginV2/Login'
import { VerifyEmail } from './containers/VerifyEmail'
import { ForgotPassword } from './containers/ForgotPasswordV2'
import { ResetPassword } from './containers/ResetPasswordV2'
import { CompanyRoot } from './containers/CompanyRoot'

import { POS } from './containers/POS'

import { AdminRoot } from './containers/Admin/AdminRoot'
import { AdminCompanies } from './containers/Admin/AdminCompanies'
import { AdminCompaniesV2 } from './containers/Admin/AdminCompaniesV2'
import { AdminGlobal } from './containers/Admin/AdminGlobal'
import { AdminExternalSources } from './containers/Admin/ExternalSources/AdminExternalSources'
import { ProvinceProductsSearch } from './containers/Admin/ProvinceProducts/ProvinceProductsSearch'
import { ProvinceProductsExport } from './containers/Admin/ProvinceProducts/ProvinceProductsExport'
import { ProvinceProductsImport } from './containers/Admin/ProvinceProducts/ProvinceProductsImport'
import { CompanySection } from './containers/Admin/CompanySection'
import { AdminProductImport } from './containers/Admin/CompanySection/AdminProductImport'
import { AdminCustomerImport } from './containers/Admin/CompanySection/AdminCustomerImport'
import { AdminOrganizations } from './containers/Admin/Organizations/AdminOrganizations'
import { AdminProductUpdate } from './containers/Admin/CompanySection/AdminProductUpdate'
import { AdminSubscriptions } from './containers/Admin/Subscriptions/AdminSubscriptions'

import { ConfigProvider } from 'antd-v4'
import { ConfigProvider as ConfigProviderV3 } from 'antd'
import { RegisterV2 } from './containers/RegisterV2'
import { AdminDevelopment } from './containers/Admin/Development/AdminDevelopment'
import { AdminV2Layout } from './containers/Admin/Layout/AdminV2Layout'
import { ResetPasswordUserMigration } from './containers/ResetPasswordUserMigration'
import { Routes } from './constants/Routes'
import { overwriteWorldpayAPIUrl } from '@getgreenline/shared'

const AnyRoute = Route as any

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_SENTRY_ENV,
    release: process.env.REACT_APP_SENTRY_RELEASE,
    integrations: [
      new CaptureConsole({
        levels: ['error'],
      }),
    ],
  })
}

// Eventually we want this to be 'true'
configure({ enforceActions: 'never' })

declare global {
  interface Window {
    Intercom: any
    mixpanel: any
  }
}

Object.typedKeys = Object.keys

const serverStore = new ServerStore()
const currentCompanyStore = new CurrentCompanyStore()

console.log(`Environment: ${process.env.NODE_ENV}`)
console.log(`API: ${process.env.REACT_APP_ROOT_API_URL}`)

if (process.env.REACT_APP_BIRCHMOUNT_URL) {
  overwriteBirchmountAPIUrl(process.env.REACT_APP_BIRCHMOUNT_URL)
}

if (process.env.REACT_APP_AUTHZ_GATEWAY_URL) {
  overwriteWorldpayAPIUrl(process.env.REACT_APP_AUTHZ_GATEWAY_URL)
}

ReactGA.initialize('UA-83019957-2')
ReactGA.pageview(window.location.pathname + window.location.search)

function loadRoute(cb: any) {
  return (module: any) => cb(null, module.default || module)
}

// This is necessary in order to preserve scroll positions for long lists with right-sidebars.
function onPageUpdate() {
  if (
    window.location.pathname.includes('dashboard/products/productListing') ||
    window.location.pathname.includes('dashboard/products/productListing/editProduct') ||
    window.location.pathname.includes('dashboard/inventory/single')
  ) {
    // Do not scroll
  } else if (window.location.hash === '') {
    window.scrollTo(0, 0)
  }
}

// Run the pageview once on startup
onPageUpdate()

window.addEventListener('storage', (e) => {
  // Handle user auth changes
  if (e.key === 'authToken' && window.location.pathname.includes('/dashboard')) {
    if (e.newValue === null) {
      // User logged out in another tab
      window.location.href = '/'
    } else {
      window.location.reload()
    }
  }
  // Handle POS auth changes
  if (e.key === 'deviceAuthToken' && window.location.pathname.includes('/pos')) {
    if (e.newValue === null) {
      // User logged out in another tab
      window.location.href = '/'
    } else {
      window.location.reload()
    }
  }
})

/**
 * Get the node from where to display the dropdown, returns root as defined if
 * the ant design config cannot find the parentNode under special circumstances,
 *
 * @param triggerNode
 * @returns {HTMLElement} container element
 */
const triggerNode = (triggerNode: HTMLElement) => {
  if (!triggerNode) return document.getElementById('root') as HTMLElement
  return triggerNode.parentNode as HTMLElement
}

ReactDOM.render(
  <Provider serverStore={serverStore} currentCompanyStore={currentCompanyStore}>
    <ConfigProviderV3 getPopupContainer={triggerNode}>
      <ConfigProvider getPopupContainer={triggerNode}>
        <Router onUpdate={onPageUpdate} history={browserHistory}>
          <Redirect from='/' to={Routes.LOGIN} />
          <Route path='/' component={App}>
            <Route path='admin' component={AdminRoot}>
              <IndexRedirect to='companies' />
              <Route path='companies' component={AdminCompanies}>
                <Route path=':companyId' component={CompanySection} />
                <Route path=':companyId/productImport' component={AdminProductImport} />
                <Route path=':companyId/productBulkUpdate' component={AdminProductUpdate} />
                <Route path=':companyId/customerImport' component={AdminCustomerImport} />
                <Route path=':companyId/subscriptions' component={AdminSubscriptions} />
              </Route>
              <Route path='global' component={AdminGlobal} />
              <Route path='externalSources' component={AdminExternalSources} />
              <Route path='provinceProducts/search' component={ProvinceProductsSearch} />
              <Route path='provinceProducts/export' component={ProvinceProductsExport} />
              <Route path='provinceProducts/import' component={ProvinceProductsImport} />
              <Redirect from='provinceProducts' to='provinceProducts/search' />

              <Route path='organizations' component={AdminOrganizations} />

              <Route path='v2' component={AdminV2Layout}>
                <IndexRedirect to='companies' />
                <Route path='companies' component={AdminCompaniesV2}>
                  <Route path=':companyId' component={CompanySection} />
                  <Route path=':companyId/productImport' component={AdminProductImport} />
                  <Route path=':companyId/productBulkUpdate' component={AdminProductUpdate} />
                  <Route path=':companyId/customerImport' component={AdminCustomerImport} />
                </Route>
                <Route path='development' component={AdminDevelopment} />
                <Route path='integrations' component={AdminExternalSources} />
                <Route path='organizations' component={AdminOrganizations} />

                <Redirect from='provinceProducts' to='provinceProducts/search' />
                <Route path='provinceProducts/search' component={ProvinceProductsSearch} />
                <Route path='provinceProducts/export' component={ProvinceProductsExport} />
                <Route path='provinceProducts/import' component={ProvinceProductsImport} />
              </Route>
            </Route>

            <Route path='deck' component={Deck} />
            {<Redirect from='login' to='loginV2' />}
            <Route path='loginV2' component={LoginV2} />
            {<Redirect from='register' to='registerV2' />}
            <Route path='registerV2' component={RegisterV2} />
            <Route path='verifyEmail' component={VerifyEmail} />
            <Route path='forgotPassword' component={ForgotPassword} />
            <Route path='forgotPasswordV2' component={ForgotPassword} />
            <Route path='resetPassword' component={ResetPassword} />
            <Route path='resetPasswordV2' component={ResetPassword} />
            <Route path='resetMigrationPassword' component={ResetPasswordUserMigration} />

            <Redirect from='company/:companyId' to='company/:companyId/dashboard' />
            <AnyRoute
              path='company/:companyId'
              component={CompanyRoot}
              getChildRoutes={(location: any, cb: any) =>
                import('./DashboardRoutes').then(loadRoute(cb))
              }
            />

            <Redirect from='pos' to='pos/sale' />
            <AnyRoute
              path='pos'
              component={POS}
              getChildRoutes={(location: any, cb: any) => import('./POSRoutes').then(loadRoute(cb))}
            />

            <Route
              path='/quickBooksCallback'
              onEnter={(nextState: any, replace: any) => {
                replace({
                  pathname: `/company/${nextState.location.query.state}/dashboard/integrations/quickBooks/authCallback`,
                  search: nextState.location.search,
                })
              }}
            />

            <Redirect from='*' to='/' />
          </Route>
        </Router>
      </ConfigProvider>
    </ConfigProviderV3>
  </Provider>,
  document.getElementById('root'),
)
