import * as React from 'react'
import Button, { ButtonProps } from 'antd/lib/button'
import { Tooltip, Icon } from 'antd'

interface Props {
  buttonProps: ButtonProps
  titleProps?: {
    title: string | JSX.Element
    widthBreakPoint?: number
  }
  icon?: string
}

export const AntButtonWrapper = (props: Props) => {
  const { buttonProps, titleProps, icon } = props
  const [width, setWidth] = React.useState(window.innerWidth)

  React.useEffect(() => {
    window.addEventListener('resize', () => setWidth(window.innerWidth))

    return function cleanup() {
      window.removeEventListener('resize', () => setWidth(window.innerWidth))
    }
  }, [])

  const renderButtonWithBreakPoint = (
    title: string | JSX.Element,
    widthBreakPoint: number,
    iconType?: string,
  ): JSX.Element => {
    return width <= widthBreakPoint ? (
      <Tooltip title={title} placement='bottom'>
        <Button {...buttonProps} />
      </Tooltip>
    ) : (
      <Button {...buttonProps}>
        {iconType && <Icon type={iconType} />}
        {title}
      </Button>
    )
  }

  return (
    <>
      {titleProps && titleProps.widthBreakPoint ? (
        renderButtonWithBreakPoint(titleProps.title, titleProps.widthBreakPoint, icon)
      ) : (
        <Button {...buttonProps}>
          {icon && <Icon type={icon} />}
          {titleProps && titleProps.title}
        </Button>
      )}
    </>
  )
}
